import React from "react";

import SkewBg from 'src/components/common/SkewBg';
import PageHeader from 'src/components/common/PageHeader';
import Avatar from './Avatar';

import { AboutWrapper, AboutInfo } from './About.style';


const About = () => {
  return (
    <AboutWrapper id="about">
      <PageHeader>About Me</PageHeader>
      <SkewBg />
      <AboutInfo>
        <div>
          <Avatar src="jv.jpg" />
        </div>
        <p>
          Hello there <span role="img" arial-label="wave_hand">👋</span> I'm Josue Villanueva by I go by JV.
          <br />
          I hope you stumbled upon this website on purpose! Otherwise, let me introduce you to my world 🌍
          <br />
          <br />
          I'm a self-taugth software engineer from Boston, MA. I've been teaching myself coding for sometime now and 
          also participating in projects as I try to break into the software engineering field.
          <br />
          Part of my work include crafting, building and deploying simple web applications that help with automation of tasks.
          <br />
          I continuously work on projects alone and in teams as I am developing myself as an engineer with no traditional background. You can check out some <a className="about__rkmscc-link" href="#projects"> projects</a> 
          I have worked on, and my <a className="about__rkmscc-link" href="https://github.com/idjevm">github</a> for more details.
          <br />
          <br />
          Whether you're having an idea about a project or a business inquiry, do not hesitate to drop a message below <a href="#contact"><span role="img" arial-label="arrow_down">⬇️</span></a>
        </p>
      </AboutInfo>
    </AboutWrapper>
  )
}

export default About;