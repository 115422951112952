import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faMedium, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPlane, faCamera, faLanguage } from "@fortawesome/free-solid-svg-icons";

import svgRect from "src/static/home_rect.svg";

import { HeroCard } from "./HeroCard";
import { HomeWrapper, Intro } from "./Home.style";

import IconLink from "src/components/common/IconLink";
import PageHeader from "src/components/common/PageHeader";
import Flex from "src/components/common/Flex";
import Button from "src/components/common/Button";

import { Card, CardIcon, CardText, CardTitle } from "src/components/common/Card";

const ThingsILove = () => (
  <Flex justify="space-between" align="center">
    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={faPlane} />
      </CardIcon>
      <CardTitle>Traveling</CardTitle>
      <CardText>
        I have been traveling alone since I was 18 years old and keep exploring different countries every year, learning
        about different people and different cultures
      </CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={faCamera} />
      </CardIcon>
      <CardTitle>Photography</CardTitle>
      <CardText>
        I enjoy photograpy, capturing a photo and freeze the moments during my trips to later go back is something I
        really enjoy :)
      </CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon="code" />
      </CardIcon>
      <CardTitle>Software Engineering</CardTitle>
      <CardText>
        I build apps that can help with every day task, specially if it is something I can automate. I also enjoy
        learning and building new projects on my own
      </CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={faLanguage} />
      </CardIcon>
      <CardTitle>Polyglot</CardTitle>
      <CardText>
        I love learning languages, spoken ones, to date, I speak fluent Spanish, English, Portuguese, and basics of
        Russian, Italian and German
      </CardText>
    </Card>
  </Flex>
);

const Home = () => {
  return (
    <HomeWrapper id="home">
      <img className="svg-rect" src={svgRect} alt="jv"></img>

      <Intro>
        {/* <Parallax y={[50, -50]} className="home__text"> */}
        <div className="home__text">
          <p>Hello 🤗, I’m</p>
          <h1>Josue Villanueva</h1>

          <p className="adjust">Software Engineer | Globetrotter | Polyglot</p>

          <div className="home__CTA">
            <Button className="cta" as="a" href="https://bit.ly/JV-Resume" target="_blank">
              Download Resume
            </Button>

            <div className="home__social">
              <IconLink label="github" icon={["fab", "github"]} href="//github.com/idjevm" />
              <IconLink label="linkedin" icon={faLinkedinIn} href="//linkedin.com/in/idjevm" />
              <IconLink label="instagram" icon={faInstagram} href="//instagram.com/idjevm" />
              <IconLink label="medium" icon={faMedium} href="//medium.com/@idjevm" />
            </div>
          </div>
        </div>
        {/* </Parallax> */}
        <HeroCard />
      </Intro>

      {/* Things I LOVE */}
      <PageHeader style={{ marginBottom: 30 }}>
        Things I do <i className="fas fa-heart" />
      </PageHeader>
      <ThingsILove />
    </HomeWrapper>
  );
};

export default Home;
